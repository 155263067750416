import React, { useState } from 'react'
import { faBars, faChevronDown, faSignOut, faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useRouteMatch } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'

interface MenuItem {
    title: string;
    route: string;
    active?: boolean;
}

export function Nav(): JSX.Element {
    const {t} = useTranslation()
    const { user, logout } = useAuth0()
    const { path } = useRouteMatch()
    const [isMenuBeingOpen, setIsMenuBeingOpen] = useState(false)
    const [isNavBeingOpen, setIsNavBeingOpen] = useState(false)

    const menuItems: Array<MenuItem> = [
        {title: 'Dashboard', route: '/'},
        {title: 'Apps', route: '/apps' },
        {title: 'Products', route: '/products'},
        {title: 'Orders', route: '/orders'}
    ]
    
    for (let index = 0; index < menuItems.length; index++) {
        const item = menuItems[index]
        if (item.route != '/' && new RegExp(`^${item.route}`).test(path)) {
            item.active = true
        } else if (path === item.route) {
            item.active = true
        }
    }

    return (
        <nav className='w-full mx-auto bg-white shadow'>
            <div className='container px-6 justify-between h-16 flex items-center lg:items-stretch mx-auto'>
                <div className='h-full flex items-center'>
                    <div className='mr-10 flex items-center'>
                        <svg aria-label='Home' id='logo' enableBackground='new 0 0 300 300' height={44} viewBox='0 0 300 300' width={43} xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                            <g>
                                <path
                                    fill='#4c51bf'
                                    d='m234.735 35.532c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16zm0 24c-4.412 0-8-3.588-8-8s3.588-8 8-8 8 3.588 8 8-3.588 8-8 8zm-62.529-14c0-2.502 2.028-4.53 4.53-4.53s4.53 2.028 4.53 4.53c0 2.501-2.028 4.529-4.53 4.529s-4.53-2.027-4.53-4.529zm89.059 60c0 2.501-2.028 4.529-4.53 4.529s-4.53-2.028-4.53-4.529c0-2.502 2.028-4.53 4.53-4.53s4.53 2.029 4.53 4.53zm-40.522-5.459-88-51.064c-1.242-.723-2.773-.723-4.016 0l-88 51.064c-1.232.715-1.992 2.033-1.992 3.459v104c0 1.404.736 2.705 1.938 3.428l88 52.936c.635.381 1.35.572 2.062.572s1.428-.191 2.062-.572l88-52.936c1.201-.723 1.938-2.023 1.938-3.428v-104c0-1.426-.76-2.744-1.992-3.459zm-90.008-42.98 80.085 46.47-52.95 31.289-23.135-13.607v-21.713c0-2.209-1.791-4-4-4s-4 1.791-4 4v21.713l-26.027 15.309c-1.223.719-1.973 2.029-1.973 3.447v29.795l-52 30.727v-94.688zm0 198.707-80.189-48.237 51.467-30.412 24.723 14.539v19.842c0 2.209 1.791 4 4 4s4-1.791 4-4v-19.842l26.027-15.307c1.223-.719 1.973-2.029 1.973-3.447v-31.667l52-30.728v94.729z'
                                />
                            </g>
                        </svg>
                        <h3 className='text-base text-gray-800 font-bold tracking-normal leading-tight ml-3 hidden lg:block'>Gateway</h3>
                    </div>
                    {/* Navigation Buttons */}
                    <ul className='pr-12 xl:flex items-center h-full hidden'>
                        {menuItems.map((item) => <li key={item.title} className='h-full'>
                            <Link className={`flex h-full items-center text-sm text-gray-800 px-10 tracking-normal ${item.active ? 'border-b-2 border-indigo-700' : ''}`} to={item.route}>{item.title}</Link>
                        </li>)}
                    </ul>
                </div>
                <div className='h-full xl:block hidden relative'>
                    <div className='w-full h-full flex items-center relative'>
                        <div aria-haspopup='true' className='cursor-pointer w-full flex items-center justify-end relative' onClick={() => setIsMenuBeingOpen(!isMenuBeingOpen)}>
                            <img className='rounded h-10 w-10 object-cover' src={user.picture} alt='avatar' />
                            <p className='text-gray-800 text-sm ml-2'>{user.nickname}</p>
                        </div>
                    </div>
                    <div className={`${isMenuBeingOpen ? '' : 'hidden'} absolute mt-1 w-40 right-0 rounded-md bg-white shadow-lg`}>
                        <ul className='rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                            <li onClick={() => logout()} className='cursor-pointer text-gray-600 text-sm leading-3 tracking-normal px-2 py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none flex items-center'>
                                <FontAwesomeIcon icon={faSignOut} />
                                <span className='ml-2'>{t('nav.menu.logout')}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='visible xl:hidden flex items-center'>
                    <ul className={`z-40 p-2 border-r bg-white absolute rounded top-0 left-0 right-0 shadow mt-16 md:mt-16 ${(isNavBeingOpen ? '' : 'hidden')}`}>
                        {/* Navigation Buttons */}
                        {menuItems.map((item) =>
                            <li key={item.title} className='flex xl:hidden flex-col cursor-pointer text-gray-600 text-base leading-3 tracking-normal py-3 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none'>
                                <div className='flex items-center'>
                                    <Link className='leading-6 ml-2 font-bold' to={item.route}>{item.title}</Link>
                                </div>
                            </li>)}
                        <li>
                            <hr className='border-b border-gray-300 w-full' />
                        </li>
                        <li className='ml-2 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-2 py-2 hover:text-indigo-700 flex items-center focus:text-indigo-700 focus:outline-none'>
                            <div className='flex items-center'>
                                <div className='w-12 cursor-pointer flex text-sm border-2 border-transparent rounded focus:outline-none focus:border-white transition duration-150 ease-in-out'>
                                    <img className='rounded h-10 w-10 object-cover' src={user.picture} alt='logo' />
                                </div>
                                <p className='leading-6 text-base ml-1 cursor-pointer'>{user.nickname}</p>
                                <div className='sm:ml-2 text-white relative'>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </div>
                            </div>
                        </li>
                        <li className='ml-2 cursor-pointer text-gray-600 text-base leading-3 tracking-normal py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none'>
                            <div className='flex items-center' onClick={() => logout()}>
                                <FontAwesomeIcon icon={faSignOut} />
                                <span className='leading-6 ml-2'>{t('nav.menu.logout')}</span>
                            </div>
                        </li>
                    </ul>
                    <div className={`open-m-menu ${isNavBeingOpen ? 'hidden' : ''}`} onClick={() => setIsNavBeingOpen(true)}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                    <div className={`close-m-menu ${isNavBeingOpen ? '' : 'hidden'}`} onClick={() => setIsNavBeingOpen(false)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>
            </div>
        </nav>
    )
}