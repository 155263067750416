import React from 'react'

export function Error404(): JSX.Element {
    return (
        <React.Fragment>
            <div className='single-error'>
                <p>404!</p>
            </div>
        </React.Fragment>
    )
}