import { gql } from '@apollo/client'

export const dashboardQuery = gql`
  query Dashboard($appID: UUID, $begin: ISO8601Date!, $end: ISO8601Date!) {
    dashboard(appID: $appID, begin: $begin, end: $end) {
      revenueOfPaid
      numOfOrders
      numOfPaidOrders
      numOfRefundedOrders
      numOfFailedOrders
      numOfPendingOrders
      numOfExpiredOrders
    }
  }
`