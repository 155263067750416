import React from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryLinkButton, Button } from '../'
import { useMutation, useQuery } from '@apollo/client'

import { getAllAppsQuery, deleteAppQuery } from '../../queries'
import { AllApps } from '../../queries/types/AllApps'
import { DeleteApp } from '../../queries/types/DeleteApp'
import { useHistory } from 'react-router-dom'
import { App } from '../../types/global'

export function AppsListing(): JSX.Element {
    const history = useHistory()
    const {t} = useTranslation()

    const { data: apps, refetch } = useQuery<AllApps>(getAllAppsQuery, {
        fetchPolicy: 'cache-and-network'
    })
    const [ deleteByID ] = useMutation<DeleteApp>(deleteAppQuery)

    function deleteApp(app: App) {
        return deleteByID(
            {
                variables: { id: app.id }
            }
        )
    }

    return (
        <>
            <div className='my-6 lg:my-12 container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between pb-4 border-b border-gray-300'>
                <div>
                    <h4 className='text-2xl font-bold leading-tight text-gray-800'>
                        {t('apps.title')}
                    </h4>
                </div>
                <div className='mt-6 lg:mt-0'>
                    <PrimaryLinkButton to='/apps/create'>{t('shared.button.label.create')}</PrimaryLinkButton>
                </div>
            </div>
            <div className='container mx-auto px-6'>
                <table className='w-full border-b'>
                    <thead className='bg-gray-100 border-b border-indigo-400'>
                        <tr className='h-14 uppercase text-gray-500 text-sm'>
                            <th>{t('apps.table.titles.id')}</th>
                            <th>{t('apps.table.titles.name')}</th>
                            <th>{t('apps.table.titles.key')}</th>
                            <th>{t('apps.table.titles.s2s')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {apps?.apps.map((app) => {
                            return (
                                <tr key={app.id} className='text-center h-20 border-b border-gray-150'>
                                    <td>{app.id}</td>
                                    <td>{app.name}</td>
                                    <td><code>{app.key}</code></td>
                                    <td>{app.s2sUrl}</td>
                                    <td>
                                        <div className='flex flex-row justify-evenly'>
                                            <Button onClick={() => history.push(`/apps/edit/${app.id}`)}>{t('apps.table.actions.edit')}</Button>
                                            <Button onClick={() => deleteApp(app).then(() => refetch())}>{t('apps.table.actions.delete')}</Button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}