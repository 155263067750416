import React from 'react'
import { faSpinner } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PrimaryButton } from '.'
import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus'
import { ApolloStateButtonAttributes } from '../types/global'
import { LazyQueryResult, MutationResult } from '@apollo/client'

export function ApolloStatePrimaryButton<D, V>(props: ApolloStateButtonAttributes<HTMLButtonElement, D>): JSX.Element {
    const loading = 'networkStatus' in props.result ? isNetworkRequestInFlight((props.result as LazyQueryResult<D, V>).networkStatus) : (props.result as MutationResult<D>).loading
    return (
        <PrimaryButton {...props}>
            {loading ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : props.children}
        </PrimaryButton>
    )
}