import { ApolloClient, HttpLink, InMemoryCache, from, NormalizedCacheObject } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { Auth0ContextInterface } from '@auth0/auth0-react'
import { toast } from 'react-toastify'

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ message }) =>
            toast.error(`[GraphQL error]: Message: ${message}`)
        )
    if (networkError) {
        toast.error(`[Network error]: ${networkError}`)
    }
})

const createAuthLink = (auth0: Auth0ContextInterface) => {
    return setContext(() =>
        auth0.getAccessTokenSilently().then((accessToken) => ({
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }))
    )
}

const backend_url = process.env.REACT_APP_BACKEND_URL || 'http://localhost'
const httpLink = new HttpLink({ uri: `${backend_url}/graphql` })
  
export default {
    backend_url: backend_url,
    auth0_domain: process.env.REACT_APP_AUTH0_DOMAIN || 'example.com',
    auth0_client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    createApolloClient: (auth0: Auth0ContextInterface): ApolloClient<NormalizedCacheObject> => {
        return new ApolloClient({
            link: from([createAuthLink(auth0), errorLink, httpLink]),
            cache: new InMemoryCache()
        })
    }
}