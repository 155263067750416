import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../primary-button'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { findProductQuery, updateProductQuery } from '../../queries'
import { FindProduct } from '../../queries/types/FindProduct'
import { UpdateProduct, UpdateProductVariables } from '../../queries/types/UpdateProduct'

export function ProductEdit(): JSX.Element {
    const {t} = useTranslation()
    const history = useHistory()
    const { id: productID } = useParams<{id: string}>()
    const [title, setTitle] = useState('')
    const [mmk, setMMK] = useState('')
    const [usd, setUSD] = useState('')
    
    const { data: productData } = useQuery<FindProduct>(findProductQuery, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: productID
        }
    })
    const [ updateProduct ] = useMutation<UpdateProduct, UpdateProductVariables>(updateProductQuery)

    const inputClassName='form-input mt-1 block w-full px-2 py-2 rounded border border-grey-300 shadow-sm focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'

    function updateThenGo() {
        updateProduct({
            variables: {
                id: productID,
                title: title,
                MMK: mmk != '' ? Number(mmk) : undefined,
                USD: usd != '' ? Number(usd) : undefined,
            }
        }).then(() => {
            history.push('/products')
        })
    }

    useEffect(() => {
        if (productData && productData.findProduct) {
            setTitle(productData.findProduct.title)
            setMMK(productData.findProduct.priceList.MMK ? `${productData.findProduct.priceList.MMK}` : '')
            setUSD(productData.findProduct.priceList.USD ? `${productData.findProduct.priceList.USD}` : '')
        }
    }, [productData])

    return (
        <>
            <div className='my-6 lg:my-12 container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between pb-4 border-b border-gray-300'>
                <div>
                    <h4 className='text-2xl font-bold leading-tight text-gray-800'>
                        {t('products-create.title')}
                    </h4>
                </div>
            </div>
            <div className='container mx-auto px-6'>
                <div className='flex flex-col'>
                    <label className='block mb-5'>
                        <span className='text-gray-700'>{t('products-create.form.label.title')}</span>
                        <input name='name' value={title} onChange={(e) => setTitle(e.target.value)} className={inputClassName} placeholder={t('products-create.form.placeholder.title')} />
                    </label>
                    <label className='block mb-5'>
                        <span className='text-gray-700'>{t('products-create.form.label.priceMMK')}</span>
                        <input name='s2s' value={mmk} onChange={(e) => setMMK(e.target.value)} className={inputClassName} placeholder='1000' />
                    </label>
                    <label className='block mb-5'>
                        <span className='text-gray-700'>{t('products-create.form.label.priceUSD')}</span>
                        <input name='s2s' value={usd} onChange={(e) => setUSD(e.target.value)} className={inputClassName} placeholder='1' />
                    </label>
                    <div className='mt-6 self-end'>
                        <PrimaryButton onClick={() => updateThenGo()} type='submit'>{t('shared.button.label.submit')}</PrimaryButton>
                    </div>
                </div>
            </div>
        </>)
}