import { gql } from '@apollo/client'

export const getAllAppsQuery = gql`
  query AllApps {
    apps {
        id
        name
        key
        s2sUrl
    }
  }
`

export const findAppQuery = gql`
  query FindApp($id: UUID!) {
    findApp(id: $id) {
        id
        name
        key
        s2sUrl
    }
  }
`

export const createAppQuery = gql`
  mutation CreateApp($name: String!, $s2sUrl: String!) {
    createApp(name: $name, s2sUrl: $s2sUrl) {
      id
      name
      key
      s2sUrl
    }
  }
`

export const updateAppQuery = gql`
  mutation UpdateApp($id: UUID!, $name: String, $s2sUrl: String) {
    updateApp(id: $id, name: $name, s2sUrl: $s2sUrl) {
      id
      name
      key
      s2sUrl
    }
  }
`

export const deleteAppQuery = gql`
  mutation DeleteApp($id: UUID!) {
    deleteApp(id: $id)
  }
`