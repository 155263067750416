import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../'

export function Login(): JSX.Element {
    const { loginWithRedirect, isLoading } = useAuth0()
    const { t } = useTranslation()
    return (
        <div className='mt-5'>
            { isLoading ? (<div>Logging you in...</div>) : (<PrimaryButton className='block mx-auto' onClick={() => loginWithRedirect()}>{t('login.button.login')}</PrimaryButton>)}
        </div>
    )
}