export function formatCurrency(number: number | bigint, currency = 'MMK'): string {
    return new Intl.NumberFormat(undefined, { style: 'currency', currency: currency }).format(number)
}

declare global {
    interface Number {
        toCurrency(currency?: string): string;
        formatted(): string;
    }
}

Number.prototype.toCurrency = function(currency = 'MMK') {
    return formatCurrency(this as number, currency)
}

Number.prototype.formatted = function() {
    return new Intl.NumberFormat().format(this as number)
}