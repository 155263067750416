import { gql } from '@apollo/client'

export const getAllOrdersQuery = gql`
  query GetAllOrders {
    orders {
        id
        currency
        amount
        status
        paymentMethod
        callbackStatus
        dueDate
        createdAt
    }
  }
`

export const findOrdersQuery = gql`
  query FindOrders($appID: UUID!, $limit: Int!) {
      findOrders(appID: $appID, limit: $limit) {
        id
        currency
        amount
        status
        paymentMethod
        callbackStatus
        dueDate
        createdAt
        product {
          id
          title
        }
      }
  }
`

export const createOrderQuery = gql`
  mutation CreateOrder($appID: UUID!, $productID: UUID!, $method: PaymentMethod!, $currency: Currency!, $next: String) {
    createOrder(appID: $appID, productID: $productID, method: $method, currency: $currency, next: $next) {
      id
      currency
      amount
      status
      paymentMethod
      callbackStatus
      dueDate
      createdAt
    }
  }
`

export const checkOrderQuery = gql`
  query CheckOrder($id: UUID!) {
    checkOrder(id: $id)
  }
`

export const updateOrderQuery = gql`
  mutation UpdateOrder($id: UUID!, $status: OrderStatus!) {
    updateOrder(id: $id, status: $status) {
      id
      currency
      amount
      status
      paymentMethod
      callbackStatus
      dueDate
      createdAt
    }
  }
`


export const refundOrderQuery = gql`
  mutation RefundOrder($id: UUID!) {
    refundOrder(id: $id)
  }
`