import React, { useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { checkOrderQuery, updateOrderQuery, refundOrderQuery } from '../../queries'
import { CheckOrder, CheckOrderVariables } from '../../queries/types/CheckOrder'
import { UpdateOrder, UpdateOrderVariables } from '../../queries/types/UpdateOrder'
import { RefundOrder, RefundOrderVariables } from '../../queries/types/RefundOrder'
import { toast } from 'react-toastify'
import { FindOrders_findOrders } from '../../queries/types/FindOrders'
import { OrderStatus } from '../../types/graphql-global-types'
import { ApolloStateButton } from '../apollo-state-button'

interface OrderRowAttributes {
    order: FindOrders_findOrders;
}

export function OrderRow(props: OrderRowAttributes): JSX.Element {
    const { t } = useTranslation()
    const [ refundOrder, refundOrderResult ] = useMutation<RefundOrder, RefundOrderVariables>(refundOrderQuery)
    const [ checkOrder, checkOrderResult ] = useLazyQuery<CheckOrder, CheckOrderVariables>(checkOrderQuery, {
        fetchPolicy: 'network-only'
    })
    const [ updateOrder, updateOrderResult ] = useMutation<UpdateOrder, UpdateOrderVariables>(updateOrderQuery)
    const order = props.order

    function markOrderPaid(order: FindOrders_findOrders) {
        updateOrder({
            variables: {
                id: order.id,
                status: OrderStatus.paymentSuccess
            }
        })
    }

    function queryOrder(order: FindOrders_findOrders) {
        checkOrder({
            variables: {
                id: order.id
            }
        })
    }

    function refund(order: FindOrders_findOrders) {
        refundOrder({
            variables: {
                id: order.id
            }
        }).then((res) => {
            if (res.data?.refundOrder === true) {
                toast.success('Refund requested')
            } else {
                toast.error('Failed to refund')
            }
        }).catch(() => {/**/})
    }

    useEffect(() => {
        if (checkOrderResult.data) {
            if (checkOrderResult.data.checkOrder) {
                toast.success('Order status matched with provider')
            } else {
                toast.error('Order status mismatched from provider')
            }
        }
    }, [checkOrderResult])

    return (
        <>
            <tr key={order.id} className='text-center h-20 border-b border-gray-150'>
                <td>{order.id}</td>
                <td>{order.product.title}</td>
                <td>{order.paymentMethod}</td>
                <td>{order.amount}</td>
                <td>{order.status}</td>
                <td>{order.callbackStatus}</td>
                <td>{order.createdAt}</td>
                <td>
                    <div className='flex flex-row justify-evenly'>
                        {order.status != OrderStatus.paymentSuccess ? <ApolloStateButton result={updateOrderResult} onClick={() => markOrderPaid(order)}>{t('orders.table.actions.markPaid')}</ApolloStateButton> : null}
                        <ApolloStateButton result={checkOrderResult} onClick={() => queryOrder(order)}>{t('orders.table.actions.check')}</ApolloStateButton>
                        {order.status == OrderStatus.paymentSuccess ? <ApolloStateButton result={refundOrderResult} onClick={() => refund(order)}>{t('orders.table.actions.refund')}</ApolloStateButton> : null}
                    </div>
                </td>
            </tr>
        </>
    )
}