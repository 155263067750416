import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../primary-button'
import { useHistory } from 'react-router-dom'
import { createAppQuery } from '../../queries'
import { CreateApp } from '../../queries/types/CreateApp'
import { useMutation } from '@apollo/client'

export function AppCreate(): JSX.Element {
    const {t} = useTranslation()
    const history = useHistory()
    const [name, setName] = useState('')
    const [s2sUrl, setS2SUrl] = useState('')
    const [ createApp ] = useMutation<CreateApp>(createAppQuery)

    const inputClassName='form-input mt-1 block w-full px-2 py-2 rounded border border-grey-300 shadow-sm focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'

    function createAndGo() {
        createApp({
            variables: {
                name: name,
                s2sUrl: s2sUrl
            }
        }).then(() => {
            history.push('/apps')
        })
    }

    return (
        <>
            <div className='my-6 lg:my-12 container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between pb-4 border-b border-gray-300'>
                <div>
                    <h4 className='text-2xl font-bold leading-tight text-gray-800'>
                        {t('apps-create.title')}
                    </h4>
                </div>
            </div>
            <div className='container mx-auto px-6'>
                <div className='flex flex-col'>
                    <label className='block mb-5'>
                        <span className='text-gray-700'>{t('apps-create.form.label.name')}</span>
                        <input name='name' value={name} onChange={(e) => setName(e.target.value)} className={inputClassName} placeholder={t('apps-create.form.placeholder.name')} />
                    </label>
                    <label className='block mb-5'>
                        <span className='text-gray-700'>{t('apps-create.form.label.s2s')}</span>
                        <input name='s2s' value={s2sUrl} onChange={(e) => setS2SUrl(e.target.value)} className={inputClassName} placeholder='https://' />
                    </label>
                    <div className='mt-6 self-end'>
                        <PrimaryButton onClick={() => createAndGo()} type='submit'>{t('shared.button.label.submit')}</PrimaryButton>
                    </div>
                </div>
            </div>
        </>)
}