import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../primary-button'
import { useHistory, useParams } from 'react-router-dom'
import { findAppQuery, updateAppQuery } from '../../queries'
import { UpdateApp } from '../../queries/types/UpdateApp'
import { useMutation, useQuery } from '@apollo/client'
import { FindApp, FindAppVariables } from '../../queries/types/FindApp'

export function AppEdit(): JSX.Element {
    const {t} = useTranslation()
    const history = useHistory()
    const { id: appID } = useParams<{id: string}>()
    const [ name, setName ] = useState('')
    const [ s2sUrl, setS2SUrl ] = useState('')
    const { data: appData } = useQuery<FindApp, FindAppVariables>(findAppQuery, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: appID
        }
    })
    const [ updateApp ] = useMutation<UpdateApp>(updateAppQuery)

    const inputClassName='form-input mt-1 block w-full px-2 py-2 rounded border border-grey-300 shadow-sm focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'

    function updateThenGo() {
        updateApp({
            variables: {
                id: appID,
                name: name,
                s2sUrl: s2sUrl
            }
        }).then(() => {
            history.push('/apps')
        })
    }

    useEffect(() => {
        if (appData && appData.findApp) {
            setName(appData.findApp.name)
            setS2SUrl(appData.findApp.s2sUrl)
        }
    }, [appData])

    return (
        <>
            <div className='my-6 lg:my-12 container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between pb-4 border-b border-gray-300'>
                <div>
                    <h4 className='text-2xl font-bold leading-tight text-gray-800'>
                        {t('apps-create.title')}
                    </h4>
                </div>
            </div>
            <div className='container mx-auto px-6'>
                <div className='flex flex-col'>
                    <label className='block mb-5'>
                        <span className='text-gray-700'>{t('apps-create.form.label.name')}</span>
                        <input name='name' value={name} onChange={(e) => setName(e.target.value)} className={inputClassName} placeholder={t('apps-create.form.placeholder.name')} />
                    </label>
                    <label className='block mb-5'>
                        <span className='text-gray-700'>{t('apps-create.form.label.s2s')}</span>
                        <input name='s2s' value={s2sUrl} onChange={(e) => setS2SUrl(e.target.value)} className={inputClassName} placeholder='https://' />
                    </label>
                    <div className='mt-6 self-end'>
                        <PrimaryButton onClick={() => updateThenGo()} type='submit'>{t('shared.button.label.submit')}</PrimaryButton>
                    </div>
                </div>
            </div>
        </>)
}