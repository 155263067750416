/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CallbackStatus {
  error = "error",
  none = "none",
  success = "success",
}

export enum Currency {
  MMK = "MMK",
  USD = "USD",
}

export enum OrderStatus {
  created = "created",
  paymentFailed = "paymentFailed",
  paymentSuccess = "paymentSuccess",
  refunded = "refunded",
  waitingForPayment = "waitingForPayment",
}

export enum PaymentMethod {
  kbzPayQR = "kbzPayQR",
  kbzPayWeb = "kbzPayWeb",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
