import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../primary-button'
import { useHistory } from 'react-router-dom'
import AppsDropdown from './apps-dropdown'
import { CreateProduct, CreateProductVariables } from '../../queries/types/CreateProduct'
import { useMutation, useQuery } from '@apollo/client'
import { createProductQuery, getAllAppsQuery } from '../../queries'
import { AllApps } from '../../queries/types/AllApps'
import { App } from '../../types/global'

export function ProductCreate(): JSX.Element {
    const {t} = useTranslation()
    const history = useHistory()
    const [ title, setTitle ] = useState('')
    const [ mmk, setMMK ] = useState('')
    const [ usd, setUSD ] = useState('')
    const [ app, setApp ] = useState<App|null>(null)
    const { data: apps } = useQuery<AllApps>(getAllAppsQuery, {
        fetchPolicy: 'cache-and-network'
    })
    const [ createProduct ] = useMutation<CreateProduct, CreateProductVariables>(createProductQuery)

    const inputClassName='form-input mt-1 block w-full px-2 py-2 rounded border border-grey-300 shadow-sm focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'

    function createAndGo() {
        if (title == '' || (mmk == '' && usd == '')) {
            return Promise.reject('fields must be filled')
        }

        if (app == null) {
            return Promise.reject('app must be selected')
        }

        return createProduct({
            variables: {
                appID: app.id,
                title: title,
                MMK: mmk != '' ? Number(mmk) : undefined,
                USD: usd != '' ? Number(usd) : undefined
            }
        }).then((app) => {
            console.log(app)
            history.push('/products')
        })
    }

    return (
        <>
            <div className='my-6 lg:my-12 container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between pb-4 border-b border-gray-300'>
                <div>
                    <h4 className='text-2xl font-bold leading-tight text-gray-800'>
                        {t('products-create.title')}
                    </h4>
                </div>
            </div>
            <div className='container mx-auto px-6'>
                <div className='flex flex-col'>
                    <label className='block mb-5'>
                        <span className='text-gray-700'>{t('products-create.form.label.app')}</span>
                        {apps ?  <AppsDropdown items={apps.apps} selected={app} onDidSelect={setApp} className='w-full' /> : null}
                    </label>
                    <label className='block mb-5'>
                        <span className='text-gray-700'>{t('products-create.form.label.title')}</span>
                        <input name='name' value={title} onChange={(e) => setTitle(e.target.value)} className={inputClassName} placeholder={t('products-create.form.placeholder.title')} />
                    </label>
                    <label className='block mb-5'>
                        <span className='text-gray-700'>{t('products-create.form.label.priceMMK')}</span>
                        <input name='s2s' value={mmk} onChange={(e) => setMMK(e.target.value)} className={inputClassName} placeholder='1000' />
                    </label>
                    <label className='block mb-5'>
                        <span className='text-gray-700'>{t('products-create.form.label.priceUSD')}</span>
                        <input name='s2s' value={usd} onChange={(e) => setUSD(e.target.value)} className={inputClassName} placeholder='1' />
                    </label>
                    <div className='mt-6 self-end'>
                        <PrimaryButton onClick={() => createAndGo()} type='submit'>{t('shared.button.label.submit')}</PrimaryButton>
                    </div>
                </div>
            </div>
        </>)
}