import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AppsDropdown from '../products/apps-dropdown'

import { AllApps } from '../../queries/types/AllApps'
import { findOrdersQuery, getAllAppsQuery,  } from '../../queries'
import { FindOrders, FindOrdersVariables } from '../../queries/types/FindOrders'
import { useLazyQuery, useQuery } from '@apollo/client'
import { OrderRow } from './row'
import { App } from '../../types/global'
import { ApolloStatePrimaryButton } from '../apollo-state-primary-button'

export function OrderListing(): JSX.Element {
    const { t } = useTranslation()
    const { data: apps } = useQuery<AllApps>(getAllAppsQuery)
    const [ fetchOrders, fetechOrdersResult ] = useLazyQuery<FindOrders, FindOrdersVariables>(findOrdersQuery, {
        fetchPolicy: 'cache-and-network'
    })
    const [app, setApp] = useState<App|null>(null)

    function showOrders() {
        if (app) {
            if (fetechOrdersResult.called) {
                return fetechOrdersResult.refetch({
                    appID: app.id,
                    limit: 100
                })
            } else {
                return fetchOrders({
                    variables: {
                        appID: app.id,
                        limit: 100
                    }
                })
            }
        }
    }
    useEffect(() => {
        showOrders()
    }, [app])

    const orders = fetechOrdersResult.data

    return (
        <>
            <div className='my-6 lg:my-12 container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between pb-4 border-b border-gray-300'>
                <div>
                    <h4 className='text-2xl font-bold leading-tight text-gray-800'>
                        {t('orders.title')}
                    </h4>
                    <ul className='flex flex-col md:flex-row items-start md:items-center text-gray-600 text-sm mt-3'>
                        <li className='flex items-center mr-3 mt-3 md:mt-0'>
                            {app ? (<span className='text-gray-400'>{t('orders.head.footer')}</span>): null}
                        </li>
                    </ul>
                </div>
                <div className='flex flex-col items-end md:flex-row md:items-center self-end mt-6 lg:mt-0'>
                    {apps ? <AppsDropdown items={apps.apps} selected={app} onDidSelect={setApp} className='w-56 mr-0 mb-3 md:mr-3 md:mb-0' /> : null}
                    <ApolloStatePrimaryButton className='w-44' onClick={() => showOrders()} result={fetechOrdersResult}>
                        {t('orders.head.buttons.refresh')}
                    </ApolloStatePrimaryButton>
                </div>
            </div>
            <div className='container mx-auto px-6'>
                <table className='w-full border-b'>
                    <thead className='bg-gray-100 border-b border-indigo-400'>
                        <tr className='h-14 uppercase text-gray-500 text-sm'>
                            <th>{t('orders.table.titles.id')}</th>
                            <th>{t('orders.table.titles.product')}</th>
                            <th>{t('orders.table.titles.paymentMethod')}</th>
                            <th>{t('orders.table.titles.amount')}</th>
                            <th>{t('orders.table.titles.status')}</th>
                            <th>{t('orders.table.titles.callback-status')}</th>
                            <th>{t('orders.table.titles.createdAt')}</th>
                            <th>{t('orders.table.titles.actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders ? orders.findOrders.map((order) => <OrderRow key={order.id} order={order} /> ) : null}
                    </tbody>
                </table>
            </div>
        </>
    )
}