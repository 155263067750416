import React, { useEffect, useState } from 'react'
import { App } from '../../types/global'
import { useTranslation } from 'react-i18next'
import { PrimaryLinkButton } from '../primary-link-button'
import AppsDropdown from './apps-dropdown'

import { useLazyQuery, useQuery } from '@apollo/client'
import { getAllAppsQuery, findProductsQuery } from '../../queries'
import { AllApps } from '../../queries/types/AllApps'
import { FindProducts, FindProductsVariables } from '../../queries/types/FindProducts'

import '../../utils'
import { ProductRow } from './row'

export function ProductListing(): JSX.Element {
    const {t} = useTranslation()
    const { data: apps } = useQuery<AllApps>(getAllAppsQuery)
    const [ fetchProducts, fetchProductsResult ] = useLazyQuery<FindProducts, FindProductsVariables>(findProductsQuery, {
        fetchPolicy: 'cache-and-network'
    })
    const [app, setApp] = useState<App|null>(null)

    useEffect(() => {
        if (app) {
            if (fetchProductsResult.refetch){
                fetchProductsResult.refetch({
                    appID: app.id
                })
            } else {
                fetchProducts({
                    variables: {
                        appID: app.id
                    }
                })
            }
        }
    }, [app])

    useEffect(() => {
        if (app == null && apps && apps.apps.length > 0) {
            setApp(apps.apps[0])
        }
    }, [apps])

    return (
        <>
            <div className='my-6 lg:my-12 container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between pb-4 border-b border-gray-300'>
                <div>
                    <h4 className='text-2xl font-bold leading-tight text-gray-800'>
                        {t('products.title')}
                    </h4>
                    <ul className='flex flex-col md:flex-row items-start md:items-center text-gray-600 text-sm mt-3'>
                        <li className='flex items-center mr-3 mt-3 md:mt-0'>
                            {app ? (<span className='text-gray-400'>{t('products.head.footer')}</span>): null}
                        </li>
                    </ul>
                </div>
                <div className='flex flex-col items-end md:flex-row md:items-center self-end mt-6 lg:mt-0'>
                    {apps ? <AppsDropdown items={apps.apps} selected={app} onDidSelect={setApp} className='w-56 mr-0 md:mr-5 mb-3 md:mb-0' /> : null}
                    <PrimaryLinkButton to='/products/create'>{t('shared.button.label.create')}</PrimaryLinkButton>
                </div>
            </div>
            <div className='container mx-auto px-6'>
                <table className='w-full border-b'>
                    <thead className='bg-gray-100 border-b border-indigo-400'>
                        <tr className='h-14 uppercase text-gray-500 text-sm'>
                            <th>{t('products.table.titles.id')}</th>
                            <th>{t('products.table.titles.title')}</th>
                            <th>{t('products.table.titles.mmk')}</th>
                            <th>{t('products.table.titles.usd')}</th>
                            <th>{t('products.table.titles.create-order')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fetchProductsResult.data ? fetchProductsResult.data.findProducts.map((product) => {
                            return (
                                <ProductRow key={product.id} product={product} app={app}></ProductRow>
                            )
                        }) : null}
                    </tbody>
                </table>
            </div>
        </>
    )
}
