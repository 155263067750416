import { useMutation } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button } from '..'
import { createOrderQuery } from '../../queries'
import { CreateOrder, CreateOrderVariables } from '../../queries/types/CreateOrder'
import { FindProducts_findProducts } from '../../queries/types/FindProducts'
import { App } from '../../types/global'
import { Currency, PaymentMethod } from '../../types/graphql-global-types'
import { ApolloStateButton } from '../apollo-state-button'

interface ProductRowAttributes {
    app: App | null
    product: FindProducts_findProducts
}

export function ProductRow(props: ProductRowAttributes): JSX.Element {
    const {t} = useTranslation()
    const history = useHistory()
    const [ createOrder, createOrderResult ] = useMutation<CreateOrder, CreateOrderVariables>(createOrderQuery)
    const product = props.product
    const app = props.app

    function createOrderAndGo(product: FindProducts_findProducts, method: PaymentMethod) {
        if (app == null) return
        createOrder({
            variables: {
                appID: app.id,
                productID: product.id,
                method: method,
                currency: Currency.MMK
            }
        }).then(res => {
            if (res.data) {
                const orderId = res.data.createOrder.id
                toast.info(`Order created: ${orderId}`)
                if (process.env.REACT_APP_FRONTEND_VIEW_ORDER_URL) {
                    const baseUrl = process.env.REACT_APP_FRONTEND_VIEW_ORDER_URL.replace(/\/$/, '')
                    window.open(`${baseUrl}/${orderId}`, '_blank')
                }
            }
        }).catch(() => {/**/})
    }

    return (
        <>
            <tr key={product.id} className='text-center h-20 border-b border-gray-150'>
                <td>{product.id}</td>
                <td>{product.title}</td>
                <td>{product.priceList.MMK?.toCurrency()}</td>
                <td>{product.priceList.USD?.toCurrency('USD')}</td>
                <td>
                    <div className='flex flex-row justify-evenly'>
                        <ApolloStateButton result={createOrderResult} onClick={() => createOrderAndGo(product, PaymentMethod.kbzPayQR)}>KPay QR</ApolloStateButton>
                        <ApolloStateButton result={createOrderResult} onClick={() => createOrderAndGo(product, PaymentMethod.kbzPayWeb)}>KPay Web</ApolloStateButton>
                    </div>
                </td>
                <td>
                    <Button onClick={() => history.push(`/products/edit/${product.id}`)}>{t('products.table.actions.edit')}</Button>
                </td>
            </tr>
        </>
    )
}