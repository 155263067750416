import { faCaretDown, faCheck } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { App } from '../../types/global'

interface Props {
    items: Array<App>
    selected?: App | null
    onDidSelect: (app: App) => void,
    className?: string
}

export default function AppsDropdown(props: Props): JSX.Element {
    const {t} = useTranslation()
    const ref = useRef<HTMLButtonElement>(null)
    const [isShowingDropdown, setIsShowingDropdown] = useState(false)

    function isSelected(isApp: App): boolean {
        return isApp.id === props.selected?.id
    }

    return (
        <div className='relative'>
            <button
                ref={ref}
                onClick={(e) => {
                    e.preventDefault()
                    ref.current?.focus()
                    setIsShowingDropdown(!isShowingDropdown)
                }}
                type='button'
                aria-haspopup='listbox'
                aria-expanded={isShowingDropdown ? 'true' : 'false'}
                aria-aria-label={t('products.aria.label.choose-an-app')}
                className={`relative bg-white border border-gray-300 rounded shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${props.className || ''}`}>
                <span className='flex items-center'>
                    {props.selected ? (
                        <>
                            <img src={`https://picsum.photos/seed/${props.selected.id}/50`} className='flex-shrink-0 h-6 w-6 rounded-full' />
                            <span className='ml-3 block truncate'>{props.selected.name}</span>
                        </>
                    ) : (<span className='ml-3 block truncate text-gray-500'>{t('products.label.choose-an-app')}</span>)}
                </span>
                <span className='ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none text-gray-400'>
                    <FontAwesomeIcon icon={faCaretDown} />
                </span>
            </button>
            <div className={`${isShowingDropdown ? '' : 'hidden'} absolute mt-1 w-full rounded-md bg-white shadow-lg`}>
                <ul tabIndex={-1}
                    role='listbox'
                    aria-activedescendant={props.selected?.id}
                    className='max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                    {props.items.map((item) => (
                        <li key={item.id}
                            id={item.id}
                            onClick={(e) => {
                                e.preventDefault()
                                setIsShowingDropdown(false)
                                props.onDidSelect(item)
                            }}
                            role='option'
                            className={`${isSelected(item) ? 'text-white bg-indigo-600' : 'text-gray-900 hover:bg-indigo-50'} cursor-default select-none relative py-2 pl-3 pr-9`}>
                            <div className='flex items-center'>
                                <img src={`https://picsum.photos/seed/${item.id}/50`} alt='' className='flex-shrink-0 h-6 w-6 rounded-full' />
                                <span className={`ml-3 block ${isSelected(item) ? 'font-semibold' : 'font-normal'} truncate`}>{item.name}</span>
                            </div>
                            <span className={`text-white absolute inset-y-0 right-0 flex items-center pr-4 ${isSelected(item) ? '' : 'hidden'}`}>
                                <FontAwesomeIcon icon={faCheck}/>
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}