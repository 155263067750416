import React, { Suspense } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom'

import { Home, ProductListing, ProductCreate, AppsListing, AppCreate, Login, Error404, Nav, OrderListing, AppEdit, ProductEdit } from './components'
import { Auth0Context, Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import configs from './configs'
import { ApolloProvider } from '@apollo/client'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type RouteEntry = {
    path: string;
    view: React.FunctionComponent<Record<string, unknown>>,
    exact?: boolean
}

const createProtected = <P extends Record<string, unknown>>(
    Component: React.ComponentType<P>
): React.FC<P> => {
    return function WithAuthenticationRequired(props: P): JSX.Element {
        const { isAuthenticated } = useAuth0()
  
        return isAuthenticated ? (
            <>
                <Nav></Nav>
                <Component {...props} />
            </>
        ) : (<Login />)
    }
}

function App(): JSX.Element {
    const routes: Array<RouteEntry> = [
        {
            path: '/apps',
            view: AppsListing,
            exact: true
        },
        {
            path: '/apps/create',
            view: AppCreate,
            exact: true
        },
        {
            path: '/apps/edit/:id',
            view: AppEdit,
            exact: true
        },
        {
            path: '/products',
            view: ProductListing,
            exact: true
        },
        {
            path: '/products/create',
            view: ProductCreate,
            exact: true
        },
        {
            path: '/products/edit/:id',
            view: ProductEdit,
            exact: true
        },
        {
            path: '/orders',
            view: OrderListing,
            exact: true
        },
        {
            path: '/',
            view: Home,
            exact: true
        },
        {
            path: '/',
            view: Error404,
            exact: false
        },
    ]

    return (
        <Suspense fallback='loading'>
            <Auth0Provider
                domain={configs.auth0_domain}
                clientId={configs.auth0_client_id}
                redirectUri={window.location.origin}
                useRefreshTokens={true}
                cacheLocation='localstorage'
                audience='dashboard'
                scope='dashboard:manage applications:manage products:manage orders:manage'
            >
                <Auth0Context.Consumer>
                    {(auth0) => {
                        return (<ApolloProvider client={configs.createApolloClient(auth0)}>
                            <main className='App'>
                                <Router>
                                    <Switch>
                                        {routes.map((route, index) => (
                                            <Route
                                                key={index}
                                                path={route.path}
                                                exact={route.exact}
                                                render={() => {
                                                    const Protected = createProtected(route.view)
                                                    return (
                                                        <>
                                                            <Protected />
                                                            <ToastContainer></ToastContainer>
                                                        </>)
                                                }}
                                            />
                                        ))}
                                    </Switch>
                                </Router>
                            </main>
                        </ApolloProvider>)
                    }
                    }
                </Auth0Context.Consumer>
            </Auth0Provider>
        </Suspense>
    )
}

export default App
