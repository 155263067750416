import React, { ReactNode, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Dashboard, DashboardVariables } from '../../queries/types/Dashboard'
import { dashboardQuery, getAllAppsQuery } from '../../queries'
import { faFileInvoice, faHourglassEnd, faHourglassHalf, faLayerMinus, faMoneyBill, faReceipt, faSigma, faSpinner, faTimesSquare, IconDefinition } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AllApps } from '../../queries/types/AllApps'
import { endOfDay, format, subDays } from 'date-fns'
import AppsDropdown from '../products/apps-dropdown'
import { startOfDay } from 'date-fns/esm'
import { PrimaryButton } from '../primary-button'
import { useTranslation } from 'react-i18next'
import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus'
import '../../utils'
import { App } from '../../types/global'

interface Props {
    title: string
    icon: IconDefinition
    iconColor?: string
    children: ReactNode
}

function Block(props: Props): JSX.Element {
    return (
        <div className='w-full md:w-1/2 lg:w-1/4 p-3'>
            <div className='bg-white border rounded shadow-sm p-2'>
                <div className='flex flex-row items-center'>
                    <div className='flex-shrink pl-2 pr-4'><FontAwesomeIcon icon={props.icon} size='3x' color={props.iconColor} /></div>
                    <div className='flex-1 text-right md:text-center'>
                        <h5 className='font-bold uppercase text-gray-500'>{props.title}</h5>
                        <h3 className='font-bold text-3xl'>{props.children}</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

function formatInputDateString(date: Date) {
    return format(date, 'yyyy-MM-dd')
}

export function Home(): JSX.Element {
    const { t } = useTranslation()
    const { data: apps } = useQuery<AllApps>(getAllAppsQuery)
    const [ app, setApp ] = useState<App|null>(null)
    const [ dateBegin, setDateBegin ] = useState<Date>(startOfDay(subDays(new Date(), 7)))
    const [ dateEnd, setDateEnd ] = useState<Date>(endOfDay(new Date()))
    const query = useQuery<Dashboard, DashboardVariables>(dashboardQuery, {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables: {
            appID: app?.id,
            begin: dateBegin,
            end: dateEnd
        }
    })

    useEffect(() => {
        query.refetch({
            appID: app?.id,
            begin: dateBegin,
            end: dateEnd
        })
    }, [app, dateBegin, dateEnd])

    const data = query.data
    if (data) {
        console.log(data)
    }

    function blocks() {
        return (<>
            <h5 className='text-xl font-medium leading-tight text-gray-800 mb-5'>{t('dashboard.titles.money')}</h5>
            <div className='flex flex-wrap'>
                <Block title='Profit' icon={faSigma} iconColor='green'>
                    {((data?.dashboard.revenueOfPaid || 0) * 0.85).toCurrency()}
                </Block>
                <Block title='Revenue' icon={faMoneyBill} iconColor='orange'>
                    {(data?.dashboard.revenueOfPaid)?.toCurrency()}
                </Block>
            </div>
            <h5 className='text-xl font-medium leading-tight text-gray-800 mt-5 mb-5'>{t('dashboard.titles.orders')}</h5>
            <div className='flex flex-wrap'>
                <Block title='Total' icon={faFileInvoice}>
                    {data?.dashboard.numOfOrders.formatted()}
                </Block>
                <Block title='Paid' icon={faReceipt} iconColor='green'>
                    {data?.dashboard.numOfPaidOrders.formatted()}
                </Block>
                <Block title='Refunded' icon={faLayerMinus} iconColor='orange'>
                    {data?.dashboard.numOfRefundedOrders.formatted()}
                </Block>
                <Block title='Pending' icon={faHourglassHalf} iconColor='orange'>
                    {data?.dashboard.numOfPendingOrders.formatted()}
                </Block>
                <Block title='Expired' icon={faHourglassEnd} iconColor='orange'>
                    {data?.dashboard.numOfExpiredOrders.formatted()}
                </Block>
                <Block title='Failed' icon={faTimesSquare} iconColor='red'>
                    {data?.dashboard.numOfFailedOrders.formatted()}
                </Block>
            </div>
        </>)
    }

    return (
        <>
            <div className='my-6 lg:my-12 container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between pb-4 border-b border-gray-300'>
                <div>
                    <h4 className='text-2xl font-bold leading-tight text-gray-800'>
                        Dashboard
                    </h4>
                </div>
                <div className='flex flex-col items-end lg:flex-row lg:items-center self-end mt-6 lg:mt-0'>
                    <input type='date' className='border border-gray-300 rounded shadow-sm mr-0 lg:mr-5 mb-3 lg:mb-0 p-2 sm:text-sm' value={formatInputDateString(dateBegin)} onChange={(e) => setDateBegin(startOfDay(new Date(e.target.value)))}></input>
                    <input type='date' className='border border-gray-300 rounded shadow-sm mr-0 lg:mr-5 mb-3 lg:mb-0 p-2 sm:text-sm' value={formatInputDateString(dateEnd)} onChange={(e) => setDateEnd(endOfDay(new Date(e.target.value)))}></input>
                    {apps ? <AppsDropdown items={apps.apps} selected={app} onDidSelect={setApp} className='w-56 mr-0 lg:mr-5 mb-3 lg:mb-0' /> : null}
                    <PrimaryButton className='w-32' onClick={() => query.refetch()}>
                        {isNetworkRequestInFlight(query.networkStatus) ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : t('dashboard.head.buttons.refresh')}
                    </PrimaryButton>
                </div>
            </div>
            <div className='container mx-auto px-6'>
                {isNetworkRequestInFlight(query.networkStatus) ? <div></div> : blocks()}
            </div>
        </>
    )
}