import { gql } from '@apollo/client'

export const getAllProductsQuery = gql`
  query AllProducts {
    products {
        id
        title
        priceList {
            MMK
            USD
        }
        createdAt
    }
  }
`

export const findProductQuery = gql`
  query FindProduct($id: UUID!) {
    findProduct(id: $id) {
      id
      title
      priceList {
          MMK
          USD
      }
      createdAt
    }
  }
`

export const findProductsQuery = gql`
  query FindProducts($appID: UUID!) {
    findProducts(appID: $appID) {
      id
      title
      priceList {
          MMK
          USD
      }
      createdAt
    }
  }
`

export const createProductQuery = gql`
  mutation CreateProduct($appID: UUID!, $title: String!, $MMK: Float, $USD: Float) {
    createProduct(appID: $appID, title: $title, MMK: $MMK, USD: $USD) {
        id
        title
        priceList {
          MMK
          USD
        }
        createdAt
      }
  }
`

export const updateProductQuery = gql`
  mutation UpdateProduct($id: UUID!, $title: String, $MMK: Float, $USD: Float) {
    updateProduct(id: $id, title: $title, MMK: $MMK, USD: $USD) {
        id
        title
        priceList {
          MMK
          USD
        }
        createdAt
      }
  }
`